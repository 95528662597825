.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .subcontainer {
    display: flex;
    flex: 1;

    .sidebarAndContentContainer {
      display: flex;
      flex-direction: row;
      min-height: 100%;
      width: 100%;
    }
  }
}

.outerContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .innerContainer {
    width: 800px;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .firstLine {

        .jwtInput {
          width: 100%;
          height: 40px;
        }
      }

      .secondLine {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 20px;

        .currencySelect {
          height: 40px;
          border-radius: 3px;
          background: white;
        }

        .input {
          height: 40px;
          width: 200px;
          border-radius: 3px;
        }
      }
    }
  }
}
@import '../../../styles/variables/_colors.scss';
@import '../../../styles/variables/_fonts.scss';

.container {
  width: 100%;
  height: 160px;
  background-image: url('../mock-images/top-bar-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: right;
  align-items: center;
  color: white;

  .balanceInfo {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    background: rgba(0, 0, 0, 0.8);
    padding: 8px;
    border-radius: 3px;

    .balanceEntry {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-family: $boldFont;
      }

      .spacer {
        width: 16px;
        height: 0;
      }
    }
  }
}
@import '../../../styles/variables/george-variables';

.container {

  .boxContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    .graphContainer {
      display: flex;
      justify-content: center;
      padding-inline: $fontMedium;

      .graphCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }
    }

    .tableContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }
}

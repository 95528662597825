@import '../variables/fonts';
@import '../variables/colors';
@import '../variables/mixins';

/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .menu-buttons {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

/* NFT Collection styles */
.nft-collection-container {
  margin: 3% 0;
  display: flex;
  width: auto;
  gap: 2%;

  @include tabletSize {
    margin: 5% 0;
  }

  /* @include mobileSize {
    margin: 5% 0;
  } */

  .nft-collection-item {
    font-family: $regularFont;
    margin: 0;
    margin-bottom: 5%;
    padding: 10px;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 36px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    .nft-collection-title {
      margin: 0;
      padding: 0 5%;

      p {
        padding: 0;
        margin: 5px 0 0;
        color: map-get($fontColors, mainBlue);
      }

      h3 {
        font-family: $xBoldFont;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }
}

/* Main button */
.main-button {
  display: flex;
  padding: 0 20px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  background: map-get($backgroundColors, lightPurple);
  color: map-get($fontColors, mainPurple);
  font-size: 14px;
  font-family: $boldFont;
  line-height: 130.6%;
  letter-spacing: 1.4px;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
}

.disabled {
  background: map-get($backgroundColors, mainGrey);
  color: map-get($fontColors, mainGrey);
  cursor: not-allowed;
}

.narrow-button {
  max-width: 160px;
  font-size: 12px;
  letter-spacing: normal;
}

/* Menu button */

.menu-button {
  display: flex;
  align-items: center;
  gap: 6px;
  color: map-get($fontColors, mainWhite);
  cursor: pointer;
  text-decoration: none;

  &.active {
    color: map-get($fontColors, mainPurple);
  }

  .menu-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .menu-button-description {
    font-family: $boldFont;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.16px;
  }
}

/* Table Button */

.table-button {
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: uppercase;
  border: none;
  background-color: inherit;
  font-family: $mediumFont;
  color: map-get($fontColors, mainPurple);
  font-size: 12px;
  letter-spacing: 2px;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }

  .table-button-text {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

/* Table wrapper */
.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 26px;
  border-radius: 12px;
  background: map-get($backgroundColors, mainWhite);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 40px;

    .title-container {
      display: flex;
      align-items: center;
      gap: 12px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 22px;
        width: 44px;
        height: 44px;
        color: map-get($fontColors, mainPurple);
        background: map-get($backgroundColors, lightPurple);

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .title {
        color: #2F2D3E;
        font-size: 25px;
        font-family: $boldFont;
      }
    }

    .header-buttons {
      display: flex;
      align-items: center;
      gap: 34px;
    }
  }

  .MuiTableContainer-root {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .MuiTableRow-root:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1.5px;
    border-color: #E9E9E9;
  }

  .MuiTableCell-root {
    color: map-get($fontColors, mainGrey);
    font-size: 16px;
    font-family: $regularFont;
    text-transform: capitalize;
  }

  .MuiTableCell-head {
    background: #FAFAFA;
    color: map-get($fontColors, mainBlack);
    font-size: 12px;
    font-family: $boldFont;
    letter-spacing: 1.68px;
    border-bottom: none;
  }
}

.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;

  .mobile-menu-packed {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .mobile-menu-items-container {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 20px;

      .mobile-menu-button {
        position: relative;
        color: map-get($fontColors, mainGrey);
        cursor: pointer;
        font-family: "HelveticaBold", serif;
        display: flex;
        gap: 6px;

        .mobile-menu-icon {
          width: 20px;
          height: 20px;

          .wedge-pointing-right {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}


.header-link {
  text-decoration: none;
  color: map-get($fontColors, mainBlack);

  .header-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .header-logo-text {
      text-transform: uppercase;
      font-family: $mediumFont;
      letter-spacing: 2px;
      margin: 0 0 0 3%;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.dropdown-menu-container {
  position: absolute;
  left: 50%;
  top: 100%;
  width: 200px;
  display: inline-flex;
  padding: 31px 41px 31px 42px;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  background: var(--color-gray-800);
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  transform: translate(-50%, 0);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 16px 16px 16px;
    border-style: solid;
    border-color: transparent transparent var(--color-gray-800) transparent;
    border-radius: 8px;
  }
}

@import "../../../styles/variables/george-variables";

.outerContainer {

  .divider {
    height: 1.5px;
    background: map-get($backgroundColors, borderGrey);
  }

  .innerContainer {
    display: flex;
    justify-content: space-between;
    color: map-get($fontColors, mainBlack);
    padding: 8px 16px 8px 16px;

    .assetInfoContainer {
      display: flex;
      gap: 9px;
      align-items: center;

      .associatedColor {
        width: 3px;
        height: 80%;
        border-radius: 3px;
      }

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;
        margin-left: 6px;

        .icon {
          width: 30px;
          height: 30px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .assetWithBalance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: $fontSizeRegular;
        letter-spacing: -0.14px;

        .assetName {
          font-family: $mediumFont;
        }

        .assetValue {
          color: map-get($fontColors, mainGrey);
          font-family: $regularFont;
        }
      }
    }

    .rightPart {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .balanceInfoContainer {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        letter-spacing: -0.14px;
        padding-inline: 6px;

        .convertedValue {
          color: map-get($fontColors, mainBlack);
          font-family: $mediumFont;
          font-size: $fontSizeRegular;
          font-weight: 600;
        }
      }

      .expanderContainer {
        display: flex;
        width: 20px;
        height: 20px;
        color: map-get($fontColors, mainGrey);
      }

      .expandedArrow {
        transform: rotate(90deg);
        color: map-get($fontColors, mainPurple);
      }
    }
  }
}

.expandedColor {
  background: map-get($backgroundColors, expandedPurple);
}

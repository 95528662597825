@import "../../styles/variables/george-variables";

.outerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: $mediumFont;

  .topBackground {
    background: linear-gradient(151deg, #7447F4 6.5%, #412789 93.95%);
    position: absolute;
    width: 100%;
    height: 160px;
    z-index: -1;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 18px;

    .topLogoContainer {
      color: map-get($fontColors, mainWhite);
      font-family: $boldFont;
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 16px;

      .backArrowContainer {
        width: 33px;
        height: 33px;
        position: absolute;
        left: calc(33px + 16px);
        transform: translateX(-100%);
        cursor: pointer;
      }

      .textWithLogoConainer {
        display: block;
        margin: 0 auto;

        .textWithLogo {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;

          .logo {
            width: 106px;
            color: map-get($fontColors, mainWhite);
          }
        }
      }
    }

    .balanceTile {
      margin-inline: 16px;
    }

    .menu {
      display: flex;
      padding: 12px 4px 12px 4px;
      margin-inline: 16px;

      .menuItem {
        color: map-get($fontColors, mainGrey);
        border-radius: 6px;
        padding: 8px;

        &:hover {
          cursor: pointer;
          color: map-get($fontColors, mainPurple);
        }
      }

      .active {
        color: map-get($fontColors, mainPurple);
        background: map-get($backgroundColors, lightPurple);
      }
    }

    .contentContainer {
      display: flex;
      padding: 0 24px 24px 24px;
    }
  }
}

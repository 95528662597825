/* Main fonts styles used in project */
@font-face {
  font-family: "HelveticaRegular";
  src: local("HelveticaRegular"), url("assets/fonts/HelveticaNowDisplayRegular/font.woff") format("woff");
}
@font-face {
  font-family: "HelveticaMedium";
  src: local("HelveticaMedium"), url("assets/fonts/HelveticaNowDisplayMd/font.woff") format("woff");
}
@font-face {
  font-family: "HelveticaBold";
  src: local("HelveticaBold"), url("assets/fonts/HelveticaNowDisplayBd/font.woff") format("woff");
}
@font-face {
  font-family: "HelveticaXBold";
  src: local("HelveticaXBold"), url("assets/fonts/HelveticaNowDisplayXBd/font.woff") format("woff");
}
/* Main font colors */
/* Responsive - mobile size mixin */
/* DashboardAmlDemo page styles */
.dashboard-main-container {
  margin: 0;
  padding: 3% 6%;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .dashboard-main-container {
    padding: 6%;
    
  }
}
.dashboard-main-container .dashboard-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}/*# sourceMappingURL=dashboard.css.map */



:root {
  /* fonts */
  --font-helvetica-now-display: "Helvetica Now Display";
  --font-inter: Inter;

  /* font sizes */
  --font-size-4xs: 9.4px;
  --font-size-3xs: 9px;
  --font-size-2xs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 19px;
  --font-size-2xl: 24px;
  --font-size-3xl: 25px;
  --font-size-4xl: 30px;
  --font-size-5xl: 40px;
  --font-size-6xl: 80px;

  /* Colors */
  --color-gray-100: #fafafa;
  --color-gray-200: #ededed;
  --acive-background: #eae4fd;
  --color-gray-400: #e9e9e9;
  --color-gray-500: #d2d2d2;
  --color-gray-600: #888;
  --color-gray-700: #7a788e;
  --color-gray-800: #2f2d3e;
  --primary-black: #202020;
  --color-gray-1000: #211134;
  --color-gray-1100: rgba(255, 255, 255, 0.6);
  --color-gray-1200: rgba(255, 255, 255, 0.2);
  --color-gray-1300: rgba(255, 255, 255, 0.1);
  --color-pink: rgba(173, 171, 237, 0.16);
  --color-red: #ef032d;
  --white: #fff;
  --primary-green: #6dff96;
  --primary-purple: #7346f3;
  --color-brown: #ff4567;
  --color-gold: #ffd66d;

  /* Gaps */
  --gap-0: 0px;
  --gap-6xs: 6px;
  --gap-5xs: 8px;
  --gap-4xs: 9px;
  --gap-3xs: 10.33px;
  --gap-2xs: 10px;
  --gap-xs: 12px;
  --gap-sm: 14px;
  --gap-md: 14.11px;
  --gap-lg: 16px;
  --gap-xl: 20px;
  --gap-2xl: 24px;
  --gap-3xl: 26px;
  --gap-4xl: 30px;
  --gap-5xl: 32px;
  --gap-6xl: 40px;

  /* Paddings */
  --padding-3xs: 1.475728154182434px;
  --padding-2xs: 3.689971446990967px;
  --padding-xs: 6.545454978942871px;
  --padding-sm: 6px;
  --padding-md: 7.5px;
  --padding-lg: 12px;
  --padding-xl: 18.75px;
  --padding-2xl: 30px;
  --padding-3xl: 37.5px;

  /* border radiuses */
  --br-3xs: 2px;
  --br-2xs: 4px;
  --br-xs: 8px;
  --br-sm: 12px;
  --br-md: 13.5px;
  --br-lg: 14px;
  --br-xl: 22px;
  --br-2xl: 36px;

  /* Effects */
  --profile-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  --nft-card-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Containers */

.container-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.header-with-button {
  justify-content: space-between;
}

.inline-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-sm);
}

.container-header-icon-div {
  align-items: flex-start;
}

.container-header-icon-div,
.container-header-icon-div-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.container-header-icon-div-body {
  border-radius: var(--br-xl);
  background-color: var(--acive-background);
  width: 44px;
  flex-shrink: 0;
  padding: var(--padding-lg);
  box-sizing: border-box;
  align-items: center;
}


.container-icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.container-filler-icon {
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  overflow: hidden;
}

.container-title {
  position: relative;
  display: inline-block;
}


/* balance and total */

.container-top,
.container-top-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
}
.container-top {
  top: 34px;
  left: 223px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}

.overview-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-6xl);
  margin-top: var(--gap-6xl);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.connected-accounts-overview-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--gap-6xl);
}

.overview-data-container {
  display: flex;
  gap: var(--gap-6xl);
  align-items: center;
}

.overview-value-container {
  font-family: "HelveticaBold", serif;
  color: var(--primary-black);
  font-size: var(--font-size-5xl);

  @media screen and (max-width: 1200px) {
    font-size: var(--font-size-3xl);
  }

  @media screen and (max-width: 860px) {
    font-size: var(--font-size-2xl);
  }
}

.overview-text-container {
  color: var(--color-gray-600);
  font-family: "HelveticaRegular", serif;
  font-size: var(--font-size-2xl);
}

.overview-icon-container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 24px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 90px;
  background: var(--acive-background, #eae4fd);

  @media screen and (max-width: 1200px) {
    padding: 16px;
  }

  @media screen and (max-width: 860px) {
    padding: 16px;
  }

  @media screen and (max-width: 480px) {
    padding: 12px;
  }
}

.overview-icon-container img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  @media screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 860px) {
    width: 25px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
}

.slash-divider {
  width: 80px;
  height: 2px;
  background: var(--color-gray-600);
  transform: rotate(-45deg);
}

.purple {
  color: var(--primary-purple);
}

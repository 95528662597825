@import "../../../../styles/variables/_colors.scss";
@import "../../../../styles/variables/_fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  color: map-get($fontColors, mainBlack);
  overflow-y: auto;
  scrollbar-width: thin;
  margin-top: 6px;

  .decisionSection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    font-family: $boldFont;
    font-size: 24px;

    .sectionHeading {
      display: flex;
      gap: 15px;
      width: 100%;

      .orderingCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        border-radius: 38px;
        background: map-get($depositsDemoColors, successGreen);
      }

      .textContainer {
        width: calc(100% - 38px - 15px);
        text-wrap: wrap;
      }
    }

    .sectionContent {
      display: flex;
      justify-content: space-evenly;
      gap: 4px;

      @media screen and (max-width: 1400px) {
        max-width: 100%;
        flex-wrap: wrap;
      }

      .amountOption {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 100px;
        height: 70px;
        flex-shrink: 0;
        border: 1.5px solid map-get($depositsDemoColors, borderGray);
        cursor: pointer;
      }

      .activeOption {
        background: map-get($depositsDemoColors, successGreen);
        border: none;
      }

      .exchangeOptionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 60px;
        cursor: pointer;
        border: 1.5px solid map-get($depositsDemoColors, borderGray);
        border-radius: 8px;
        padding: 16px;
        flex-shrink: 0;

        .icon {
          width: 42px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 100%;
          }
        }

        .name {
          font-family: $boldFont;
          font-size: 12px;
          text-transform: uppercase;
        }
      }

      .notAllowed {
        cursor: not-allowed;
      }
    }
  }
}
@import '../../../styles/variables/george-variables';

.circleContainer {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: map-get($backgroundColors, mainWhite);
  padding: 16px 16px 0 16px;

  .graphSvg {
    width: 100%;
  }

  .balanceDisplay {
    display: flex;
    justify-content: center;
    position: absolute;
    text-align: center;
    font-weight: bold;
    width: 70%;

    .graphTextArea {
      background: map-get($backgroundColors, mainWhite);
      width: calc(100% - 10px);
      border-radius: 50%;
      aspect-ratio: 1 / 1;

      .graphTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        gap: 4px;

        .heading {
          color: map-get($fontColors, mainGrey);
          font-size: 20px;
          font-weight: 550;
        }

        .balance {
          color: map-get($fontColors, mainBlack);
          font-size: $fontBanner;
          font-weight: 700;
        }
      }
    }
  }
}

.arcWithShadow {
  filter: drop-shadow(0 10px 2px rgba(0, 0, 0, .2)); // TODO fix shadow
}

@import "../../../styles/variables/george-variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;

  .accountRow {
    cursor: pointer;
  }

  .connectButton {
    height: 44px;
    font-family: $boldFont;
    font-size: $fontVerySmall;
    letter-spacing: -0.16px;
  }
}

.backArrowContainer {

}
.del-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-6xs);
    color: var(--color-red);
    cursor: pointer;
  }

.del-img {
    width: 20px;
    height: 20px;
    overflow: hidden;
}

.del-b {
    position: relative;
    text-decoration: underline;
    letter-spacing: 0.01em;
    line-height: 140%;
    display: inline-block;
}
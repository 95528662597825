@import "../../../styles/variables/george-variables";

.deltaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $fontMedium;

  .percentChange {
    display: flex;
    color: map-get($fontColors, mainGrey);

    .text {
    }
  }

  .absoluteChange {
    font-family: $boldFont;
  }
}

.deltaTriangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
}

.deltaTrianglePositive {
  color: map-get($legendColors, successGreen);
}

.deltaTriangleNegative {
  color: map-get($legendColors, errorRed);
  transform: rotate(180deg);
}

.colorPositive {
  color: map-get($legendColors, successGreen);
}

.colorNegative {
  color: map-get($legendColors, errorRed);
}

.alignRight {
  align-items: flex-end;
}

.alignLeft {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.oneline {
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
}

@import "../../../styles/variables/george-variables";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  .leftPart {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;

    .providerIconContainer {
      img {
        width: 30px;
        height: 30px;
      }
    }

    .nameAndAddressContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      letter-spacing: -0.14px;
      font-size: $fontSizeRegular;
      font-family: $regularFont;

      .providerName {
        color: map-get($fontColors, mainBlack);
        font-weight: 600;
      }

      .address {
        color: map-get($fontColors, mainGrey);
        font-weight: 400;
      }
    }
  }

  .rightPart {
    display: flex;
    gap: 10px;
    align-items: center;

    .balanceContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-family: $regularFont;
      font-size: $fontSizeRegular;
      letter-spacing: -0.14px;

      .fiatValue {
        color: map-get($fontColors, mainBlack);
        font-weight: 600;
      }

      .cryptoValue {
        color: map-get($fontColors, mainGrey);
        font-weight: 400;
      }
    }

    .expanderContainer {
      color: map-get($fontColors, mainGrey);
      width: 20px;
      height: 20px;
    }
  }
}
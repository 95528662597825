@import "../../../../styles/variables/george-variables";

.container {
  padding: 16px;

  .titleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .leftPart {
      .heading {
        color: map-get($fontColors, mainBlack);
        font-family: $boldFont;
        font-size: $fontMedium;
        letter-spacing: -0.16px;
      }

      .timeframe {
        color: map-get($fontColors, mainGrey);
        font-family: $regularFont;
        font-size: $fontSizeRegular;
        font-weight: 400;
        letter-spacing: -0.13px;
      }
    }

    .rightPart {

      .icon {
        width: 30px;
        height: 30px;
        color: map-get($fontColors, mainPurple);
      }
    }
  }

  .txRowContainer {
    width: 100%;
  }

  .loadMoreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: $regularFont;
    font-size: $fontSizeRegular;

    .loadingText {
      color: map-get($fontColors, mainGrey);
    }

    .loadMoreText {
      color: map-get($fontColors, mainPurple);
      cursor: pointer;
    }
  }
}

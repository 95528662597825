@import "../../../styles/variables/george-variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background: map-get($backgroundColors, mainWhite);
  padding: 16px 16px 16px 16px;

  .headingRowContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .headingText {
      color: map-get($fontColors, mainBlack);
      font-family: $regularFont;
      font-size: $fontMedium;
      font-weight: 600;
      letter-spacing: -0.16px;
    }

    .headingIcon {
      color: map-get($fontColors, mainPurple);
      width: 20px;
      height: 20px;
    }
  }

  .deltasRowContainer {
    display: flex;
    justify-content: space-between;
  }

  .chart {
    font-size: $fontSizeRegular;
    color: map-get($fontColors, mainGrey);
  }
}

.customTooltip {
  padding: 8px;
  background-color: map-get($backgroundColors, darkGrey);
  border-radius: 8px;

  .title {
    font-family: $boldFont;
    color: white;
    margin: 0;
  }

  .subtitle {
    font-size: 10px;
    color: map-get($fontColors, lightGrey);
    margin: 0;
  }
}

.chartActiveDot {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}
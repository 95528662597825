@import "../../../styles/variables/_colors.scss";
@import "../../../styles/variables/_fonts.scss";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 240px;
  background: map-get($depositsDemoColors, sidebarBg);
  color: map-get($fontColors, mainBlack);

  .menuItems {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .menuItemContainer {
      display: flex;
      justify-content: left;
      gap: 8px;
      padding: 12px;
      cursor: pointer;
      border-radius: 12px;
      width: 100%;

      .icon {
        width: 20px;
        height: 20px;
      }

      .title {
        font-family: $boldFont;
        font-size: 14px;
      }
    }

    .active {
      background: map-get($depositsDemoColors, successGreen);
    }
  }
}

.mobileContainer {
  width: 40px;
}

@import "../../../styles/variables/_colors.scss";

.container {
  width: calc(100% - 240px);
  height: 100%;
  background: map-get($depositsDemoColors, mainBg);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1400px) {
    width: calc(100% - 40px);
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 120px);
    height: calc(100% - 120px);
    margin: 60px;

    @media screen and (max-width: 1400px) {
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      margin: 0 0 30px 0;
    }

    .paymentProviders {
      display: flex;
      justify-content: left;
      gap: 16px;
      width: 100%;

      .paymentOption {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 10px;
        padding-inline: 16px;
        margin-bottom: 16px;
        border: 1.5px solid map-get($depositsDemoColors, borderGray);
        cursor: pointer;

        .selectedOptionBottomCover {
          position: absolute;
          height: 25px;
          top: calc(100% - 6px);
          width: 100%;
          background: map-get($depositsDemoColors, contentWhite);
          border-right: 1.5px solid map-get($depositsDemoColors, borderGray);
          border-left: 1.5px solid map-get($depositsDemoColors, borderGray);
        }
      }

      .paymentOptionFirst {
        .selectedOptionBottomCover {
          height: 30px;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      background: map-get($depositsDemoColors, contentWhite);
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1.5px solid map-get($depositsDemoColors, borderGray);
      padding: 16px 8px 16px 8px;
    }
  }
}
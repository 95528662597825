@import '../variables/fonts';
@import '../variables/colors';

/* Responsive - mobile size mixin */
@mixin mobileSize {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin tabletSize {
  @media (max-width: 1100px) {
    @content;
  }
}

$fontVerySmall: 12px;
$fontSizeRegular: 14px;
$fontMedium: 16px;
$fontSemiLarge: 17px;
$fontLarge: 18px;
$fontTitle: 19px;
$fontTitleLarge: 20px;
$fontBannerSmall: 24px;
$fontBanner: 30px;
$fontHeading: 35px;

/* Main fonts styles used in project */
@font-face {
  font-family: OpenSansRegular;
  src: local('OpenSansRegular'),
  url('../../assets/fonts/OpenSansRegular/font.woff') format('woff');
}

@font-face {
  font-family: OpenSansMedium;
  src: local('OpenSansMedium'),
  url('../../assets/fonts/OpenSansMd/font.woff') format('woff');
}

@font-face {
  font-family: OpenSansBold;
  src: local('OpenSansBold'),
  url('../../assets/fonts/OpenSansBd/font.woff') format('woff');
}

@font-face {
  font-family: OpenSansXBold;
  src: local('OpenSansXBold'),
  url('../../assets/fonts/OpenSansXBd/font.woff') format('woff');
}


//Use this way: font-family: $boldFont;
//font-weight: 400;
$regularFont: 'OpenSansRegular';
//font-weight: 500;
$mediumFont: 'OpenSansMedium';
//font-weight: 700;
$boldFont: 'OpenSansBold';
//font-weight: 800;
$xBoldFont: 'OpenSansXBold';


/* Main font colors */
$fontColors: (
  absoluteBlack: #000000,
  mainBlack: #0A285C,
  mainPurple: #7346f3,
  mainBlue: #2AA3EF,
  mainGrey: #677F9D,
  mainWhite: #FFFFFF,
  lightGrey: #AFACC3,
);

$backgroundColors: (
  lightPurple: #EAE4FD,
  mainWhite: #FFF,
  borderGrey: #EEF2F5,
  expandedPurple: #F8F5FF,
  darkGrey: #2F2D3E,
);

$legendColors: (
  successGreen: #00C853,
  errorRed: #FF1744,
);

$tagColors: (
  successGreen: #6DFF96,
);

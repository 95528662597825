@import '../../../styles/variables/george-variables';

.container {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: map-get($backgroundColors, mainWhite);
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.07);
  padding: 20px;

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .heading {
      color: map-get($fontColors, mainBlack);
      font-family: $boldFont;
      font-size: $fontMedium;
    }

    .balanceContainer {
      display: flex;
      flex-direction: column;

      .balance {
        color: map-get($fontColors, mainPurple);
        font-family: $mediumFont;
        font-size: $fontBanner;
        letter-spacing: -0.3px;
      }
    }
  }

  .logoContainer {

    .logo {
      width: 54px;
      height: 54px;
    }
  }
}

@import "../../../../styles/variables/george-variables";

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: self-start;
  color: map-get($fontColors, mainGrey);
  font-size: $fontMedium;
  font-family: $mediumFont;
  border-radius: 12px;
  padding: 4px 0 4px 0;

  .datetime {
    display: flex;
    flex-direction: column;
    font-size: $fontVerySmall;
  }

  .tooltipCategoryList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .halfWidth {
      width: 50%;
    }

    .tooltipCategoryValue{
      margin-top: 4px;
    }
  }

  .accountName {
    text-transform: capitalize;
  }

  .currencyDelta {

    .currencyDeltaValue {
      display: flex;
      gap: 8px;
      text-transform: uppercase;
    }

    .deltaValuePositive {
      color: map-get($legendColors, successGreen);
    }

    .deltaValueNegative {
      color: map-get($legendColors, errorRed);
    }
  }

  .transfer {
    display: flex;
    gap: 4px;

    .fromCurrencies {
      font-family: $regularFont;
      color: map-get($fontColors, mainBlack);
    }

    .toCurrencies {
      font-family: $regularFont;
      color: map-get($fontColors, mainBlack);
    }
  }
}
